const Shop = () => {
    return (
        <>
          {/* SMALL */}
          <div className="sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
              <div id="hero" className="hero h-[8rem] shadow-xl">
                <div className="max-w-md">
                    <a href="/home">
                    <img className="h-12 hover:opacity-85" src="/items/bvmwLOGO.png" alt="Brasov Most Wanted"/>
                    </a>
                </div>
            </div>


            <div className="flex items-center justify-center mt-12 mb-12">
                <div className="grid grid-cols-1 gap-4">

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_W.jpg" alt="Tricou Brasov Most Wanted Alb" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Alb</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_B.jpg" alt="Tricou Brasov Most Wanted Negru" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Negru</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_THEGOAT.jpg" alt="Sticker Brasov Most Wanted The GOAT" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker "THE G.O.A.T"</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_BVMW.jpg" alt="Sticker Brasov Most Wanted" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker Brasov Most Wanted</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_TURBINA.jpg" alt="Breloc Brasov Most Wanted Turbina" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Turbina</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_SUSPENSIE.jpg" alt="Breloc Brasov Most Wanted Suspensie Sport" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Suspensie Sport</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_JANTA.jpg" alt="Breloc Brasov Most Wanted Janta" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Janta</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                </div>
            </div>
          </div>


          {/* MEDIUM */}
          <div className="sm:hidden md:block lg:hidden xl:hidden 2xl:hidden">
              <div id="hero" className="hero h-[10rem] shadow-xl">
                <div className="max-w-md">
                    <a href="/home">
                    <img className="h-16 hover:opacity-85" src="/items/bvmwLOGO.png" alt="Brasov Most Wanted"/>
                    </a>
                </div>
            </div>


            <div className="flex items-center justify-center mt-12 mb-12">
                <div className="grid grid-cols-2 gap-4">

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_W.jpg" alt="Tricou Brasov Most Wanted Alb" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Alb</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_B.jpg" alt="Tricou Brasov Most Wanted Negru" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Negru</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_THEGOAT.jpg" alt="Sticker Brasov Most Wanted The GOAT" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker "THE G.O.A.T"</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_BVMW.jpg" alt="Sticker Brasov Most Wanted" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker Brasov Most Wanted</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_TURBINA.jpg" alt="Breloc Brasov Most Wanted Turbina" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Turbina</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_SUSPENSIE.jpg" alt="Breloc Brasov Most Wanted Suspensie Sport" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Suspensie Sport</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_JANTA.jpg" alt="Breloc Brasov Most Wanted Janta" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Janta</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                </div>
            </div>
          </div>


          {/* LARGE */}
          <div className="sm:hidden md:hidden lg:block xl:hidden 2xl:hidden">
              <div id="hero" className="hero h-[12rem] shadow-xl">
                <div className="max-w-md">
                    <a href="/home">
                    <img className="h-24 hover:opacity-85" src="/items/bvmwLOGO.png" alt="Brasov Most Wanted"/>
                    </a>
                </div>
            </div>


            <div className="flex items-center justify-center mt-12 mb-12">
                <div className="grid grid-cols-3 gap-4">

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_W.jpg" alt="Tricou Brasov Most Wanted Alb" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Alb</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_B.jpg" alt="Tricou Brasov Most Wanted Negru" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Negru</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_THEGOAT.jpg" alt="Sticker Brasov Most Wanted The GOAT" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker "THE G.O.A.T"</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_BVMW.jpg" alt="Sticker Brasov Most Wanted" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker Brasov Most Wanted</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_TURBINA.jpg" alt="Breloc Brasov Most Wanted Turbina" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Turbina</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_SUSPENSIE.jpg" alt="Breloc Brasov Most Wanted Suspensie Sport" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Suspensie Sport</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_JANTA.jpg" alt="Breloc Brasov Most Wanted Janta" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Janta</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                </div>
            </div>
          </div>


          {/* EXTRA LARGE */}
          <div className="sm:hidden md:hidden lg:hidden xl:block 2xl:hidden">
          <div id="hero" className="hero h-[12rem] shadow-xl">
                <div className="max-w-md">
                    <a href="/home">
                    <img className="h-24 hover:opacity-85" src="/items/bvmwLOGO.png" alt="Brasov Most Wanted"/>
                    </a>
                </div>
            </div>


            <div className="flex items-center justify-center mt-12 mb-12">
                <div className="grid grid-cols-3 gap-4">

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_W.jpg" alt="Tricou Brasov Most Wanted Alb" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Alb</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_B.jpg" alt="Tricou Brasov Most Wanted Negru" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Negru</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_THEGOAT.jpg" alt="Sticker Brasov Most Wanted The GOAT" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker "THE G.O.A.T"</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_BVMW.jpg" alt="Sticker Brasov Most Wanted" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker Brasov Most Wanted</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_TURBINA.jpg" alt="Breloc Brasov Most Wanted Turbina" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Turbina</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_SUSPENSIE.jpg" alt="Breloc Brasov Most Wanted Suspensie Sport" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Suspensie Sport</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_JANTA.jpg" alt="Breloc Brasov Most Wanted Janta" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Janta</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                </div>
            </div>
          </div>


          {/* EXTRA LARGE x2 */}
          <div className="sm:hidden md:hidden lg:hidden xl:hidden 2xl:block">
          <div id="hero" className="hero h-[12rem] shadow-xl">
                <div className="max-w-md">
                    <a href="/home">
                    <img className="h-24 hover:opacity-85" src="/items/bvmwLOGO.png" alt="Brasov Most Wanted"/>
                    </a>
                </div>
            </div>


            <div className="flex items-center justify-center mt-12 mb-12">
                <div className="grid grid-cols-3 gap-4">

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_W.jpg" alt="Tricou Brasov Most Wanted Alb" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Alb</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/tricou_THEGOAT_B.jpg" alt="Tricou Brasov Most Wanted Negru" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Tricou "THE G.O.A.T" Negru</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_THEGOAT.jpg" alt="Sticker Brasov Most Wanted The GOAT" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker "THE G.O.A.T"</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/sticker_BVMW.jpg" alt="Sticker Brasov Most Wanted" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Sticker Brasov Most Wanted</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_TURBINA.jpg" alt="Breloc Brasov Most Wanted Turbina" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Turbina</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_SUSPENSIE.jpg" alt="Breloc Brasov Most Wanted Suspensie Sport" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Suspensie Sport</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                    <div className="bg-[#353535] h-[20rem] w-[15rem] rounded-lg">
                        <img src="/items/breloc_JANTA.jpg" alt="Breloc Brasov Most Wanted Janta" className="rounded-lg h-[15rem] border-2 border-[#353535] hover:opacity-75"/>
                        <div id="Description" className="m-2">
                            <p className="font-bold text-sm">Breloc Janta</p>
                            <div className="flex justify-end mt-4"><button className="text-sm bg-zinc-900 border-4 border-zinc-900 rounded-xl">OUT OF STOCK</button></div>
                        </div>
                    </div>

                </div>
            </div>
          </div>
        </>
    );
  };
  
  export default Shop;
  