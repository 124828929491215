import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import Home from './pages/Home';
import Shop from './pages/Shop';
import Rules from './pages/Rules';
import Partener from './pages/Partener';

import Admin from './pages/Admin';

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route path='/' element={<Navigate to='/home' />} />
		      <Route path='/*' element={<Navigate to='/home' />} />
          <Route path='/home' element={<Home />} />
          <Route index element={<Home />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/partener' element={<Partener />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
