import React, { useState, useEffect } from 'react';

const Admin = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.oxidev.ro/get'); // Înlocuiți PORT cu portul pe care îl folosiți pentru serverul Express
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData.reverse());
      } else {
        console.error('Eroare la obținerea datelor');
      }
    } catch (error) {
      console.error('Eroare la obținerea datelor:', error);
    }
  };

  return (
    <>
    <ul className='h-screen w-full p-4 gap-y-4 gap-x-4 flex flex-col'>
      {data.map((item, index) => (
          <li key={index} className='bg-black/75 h-full justify-start p-2'>
            <h1 className='text-xl font-bold'>Nume/Prenume: {item.numePrenume}</h1>
            <p className='text-lg font-bold'>Email:{item.email}</p>
            <p className='text-lg font-bold'>Număr Telefon: {item.numarTelefon}</p>
            <p className='text-lg font-bold'>Număr Imatriculare: {item.numarImatriculare}</p>
            <p className='text-lg font-bold'>Descriere: {item.descriere}</p>
          </li>
      ))}
    </ul>
    </>
  );
};

export default Admin;
