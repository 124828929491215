import Navbar from "../components/Navbar";
import React, { useEffect } from "react";
import { createStars } from '../components/Stars';

const Rules = () => {

    useEffect(() => {
        createStars();
      }, []);

    return (
        <>
        <Navbar />

        <div className="star-field flex flex-col mt-24 w-full items-center justify-center font-body p-24">
            <div className="sm:text-sm md:text-sm lg:text-xl xl:text-xl 2xl:text-xl font-bold gap-y-2 opacity-75">
                <p className="mb-2">- Nu aruncati deseuri pe jos, exista locuri special desemnate pentru aruncarea acestora.</p>
                <p className="mb-2">- Sunt interzise BURNOUT / CERCULETE / DRIFTURI in locatia evenimentului sau in parcarea acestuia fara aprobare.</p>
                <p className="mb-2">- Este interzisa folosirea sistemulului audio personal in cadrul evenimentului sau in parcarea acestuia fara aprobare.</p>
                <p className="mb-2">- Este interzisa turarea masinilor in cadrul evenimentului sau in parcarea acestuia fara aprobare.</p>
                <p className="mb-2">- Este interzisa deteroirarea bunurilor propietatii sau a altor participanti.</p>
                <p className="mb-2">- Este interzisa aducerea de modificari / schimbarea pieselor ale autovehiculului in cadrul evenimentulu.</p>
                <p className="mb-2">- Este interzisa instigarea catre violente, fie ele verbale sau fizice.</p>
                <p className="mb-2">- Este interzis transportul cu masina in cadrul evenimentului fara aprobare.</p>
                <p className="mb-2">- ACCESUL CU MASINA ESTE PERMIS DOAR PERSOANELOR INSCRISE SI ACCEPTATE IN LIMITA LOCURILOR DISPONIBILE.</p>
            </div>

            <div className="mt-8 sm:text-md md:text-md lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold mb-2 ml-2 mr-2">
                <p className="mb-4">Acestea sunt regulile principale ce trebuiesc respectate, in caz contrar, persoanele ce nu respecta acest regulament sunt excluse din cadrul evenimentului.</p>
                <p className="mb-4">In caz ca sunt participanti care doresc sa isi amplaseze materiale promotionale precum cort, steaguri, etc..., va rugam sa ne contactati la adresa de e-mail contact@brasovmostwanted.ro sau pe paginile de instagram: @brasovmostwanted & @wheelsandwhistles.ro . </p>
                <p className="mb-4">Pentru propunerile de colaborare, sponsorizare sau parteneriat, va rugam sa accesati sectiunea de sponsori de pe pagina principala unde puteti aplica.</p>
            </div>
        </div>

        </>
    )
}

export default Rules;