import React, {useEffect} from 'react';
import NavBar from "../components/Navbar"
import Countdown from '../components/Countdown';
import { createStars } from '../components/Stars';

const Home = () => {

    useEffect(() => {
    createStars();
  }, []);

  return (
    <>
        <NavBar />

        <div className="relative h-screen w-full">
            <div className="sm:hidden">
                <video autoPlay loop muted className="relative h-screen w-full object-cover">
                    <source src="/bgVideo.mp4" type="video/mp4" />
                </video>
            </div>

            <div className="md:hidden lg:hidden xl:hidden 2xl:hidden">
                <img src="/bgGif.gif" alt="bgGif" className="relative h-screen w-full object-cover"/>
            </div>

            <div className="absolute inset-0 z-1 flex items-end">
                <div className="bg-gradient-to-t from-[#2A474B]/100 to-[#2A474B]/0 h-64 w-full"></div>
            </div>

            <div className="absolute inset-0 z-0 bg-[#2A474B] bg-opacity-75 h-screen w-full flex flex-col items-center justify-center select-none">
                <img src="/ww.png" alt="logo" className="animate__animated animate__fadeInRight m-0 h-2/4 w-auto sm:h-64 sm:w-96 "/>
                <h1 className="font-bold font-body text-4xl animate__animated animate__fadeIn">19 - 20 IULIE</h1>
                <p className="font-body animate__animated animate__fadeIn">La Baza Partiei Cazacu, Azuga</p>
                <a href="/"><button className="animate__animated animate__fadeIn mt-2 relative bg-[#2B8477] font-bold font-body text-xl p-2 rounded-xl w-42 h-10 flex items-center justify-center hover:bg-[#2A474B]/85">Inscrieri Oprite</button></a>
            </div>
        </div>

        <div className="relative w-full h-screen">
            <div className="w-full flex flex-col items-center mt-8 gap-y-8 gap-x-8">
                <div className="absolute inset-0 z-1 flex items-end">
                    <div className="bg-gradient-to-t from-[#2A474B]/100 to-[#2A474B]/0 h-64 w-full"></div>
                </div> 

                <div className='star-field w-full flex flex-col items-center mt-8 gap-y-8 gap-x-8 absolute inset-0 z-99'>

                <div className="w-2/4 flex items-start justify-center">
                    <h1 className="font-bold font-body text-3xl mt-2">Day <span className="text-[#2B8477]">ONE</span></h1>
                </div>
                <div className="w-2/4 flex flex-inline sm:flex-col sm:items-center sm:gap-y-8 justify-center gap-x-8">
                    <div className="w-64 h-auto bg-[#2A474B]/75 shadow-2xl flex items-center justify-center">
                        <img src="/Checkin.png" alt="checkin" className="h-auto w-56"/>
                    </div>

                    <div className="w-64 h-auto bg-[#2A474B]/75 shadow-2xl flex items-center justify-center">
                        <img src="/Social.png" alt="social" className="h-auto w-56"/>
                    </div>

                    <div className="w-64 h-auto bg-[#2A474B]/75 shadow-2xl flex items-center justify-center">
                        <img src="/Movie.png" alt="movie" className="h-auto w-56"/>
                    </div>
                </div>

                <div className="w-2/4 flex items-start justify-center">
                    <h1 className="font-bold font-body text-3xl">Day <span className="text-[#2B8477]">TWO</span></h1>
                </div>
                <div className="w-2/4 flex flex-inline sm:flex-col sm:items-center sm:gap-y-8 justify-center gap-x-8">
                    <div className="w-64 h-auto bg-[#2A474B]/75 shadow-2xl flex items-center justify-center">
                        <img src="/Car_show.png" alt="car_show" className="h-auto w-56"/>
                    </div>

                    <div className="w-64 h-auto bg-[#2A474B]/75 shadow-2xl flex items-center justify-center">
                        <img src="/Contest.png" alt="contest" className="h-auto w-56"/>
                    </div>

                    <div className="w-64 h-auto bg-[#2A474B]/75 shadow-2xl flex items-center justify-center">
                        <img src="/Music_vibe.png" alt="music & vibe" className="h-auto w-56"/>
                    </div>
                </div>

                <div className='relative h-screen w-full flex flex-col items-center mt-auto'>
                    <div className='flex items-center justify-center w-full flex-col'>
                        <div className="w-2/4 flex items-start justify-center mt-12 mb-4">
                            <h1 className="font-bold font-body text-3xl">Tickets</h1>
                        </div>

                        <div className='w-2/4 flex flex-inline sm:flex-col sm:items-center sm:gap-y-8 justify-center gap-x-8'>
                        <a href="https://www.livetickets.ro/bilete/wheels-whistles-2024">
                            <div className='w-64 bg-white/95 rounded-xl shadow-xl flex flex-col items-center p-4 hover:bg-white/85'>
                                <img src="/livetk.png" alt='live tickets' className='h-12'/>
                                <div className='flex flex-col items-start w-full h-full mt-2'>
                                <h1 className='font-body font-bold text-xl text-black'>Bilet vizitator</h1>
                                <p className='font-body text-md text-black/75 mt-4'>Pret :</p>
                                <p className='font-body font-bold text-4xl text-[#2B8477] mt-0'>20 RON</p>
                                <div className='w-full h-[2px] bg-black mt-4'></div>
                                <p className='font-body text-md text-black/75 mt-4'>Acest bilet vă oferă acces în cadrul evenimentului ca vizitator pe data de 20 Iulie 2024.</p>
                                </div>
                                <a href="https://www.livetickets.ro/bilete/wheels-whistles-2024"><button className="animate__animated animate__fadeIn mt-2 relative bg-[#2B8477] font-bold font-body text-xl p-2 rounded-xl w-32 h-10 flex items-center justify-center hover:bg-[#2A474B]/85 mt-4">Cumpara</button></a>
                            </div>
                            </a>

                            <a href="https://www.livetickets.ro/bilete/wheels-whistles-2024">
                            <div className='w-64 bg-white/95 rounded-xl shadow-xl flex flex-col items-center p-4 hover:bg-white/85'>
                                <img src="/livetk.png" alt="live tickets" className='h-12'/>
                                <div className='flex flex-col items-start w-full h-full mt-2'>
                                <h1 className='font-body font-bold text-xl text-black'>Bilet auto</h1>
                                <p className='font-body text-md text-black/75 mt-4'>Pret :</p>
                                <p className='font-body font-bold text-4xl text-[#2B8477] mt-0'>70 RON</p>
                                <div className='w-full h-[2px] bg-black mt-4'></div>
                                <p className='font-body text-md text-black/75 mt-4'>Acest bilet oferă acces în cadrul evenimentului cu autovehiculul. Înainte de a achiziționat acest bilet va rugăm să luați în considerare ca înscrierea dumneavoastră a fost acceptată de membrii staff pe site-ul în cauza.</p>
                                </div>
                                <a href="https://www.livetickets.ro/bilete/wheels-whistles-2024"><button className="animate__animated animate__fadeIn mt-2 relative bg-[#2B8477] font-bold font-body text-xl p-2 rounded-xl w-32 h-10 flex items-center justify-center hover:bg-[#2A474B]/85 mt-4">Cumpara</button></a>
                            </div>
                            </a>
                        </div>
                    </div>

                    <div className="w-2/4 h-screen flex flex-inline sm:flex-col items-center justify-center mt-12 gap-x-4 gap-y-4">
                        <a href='/inscrieri' className='inscrieri w-2/4 h-96 sm:w-80 bg-white rounded-xl flex justify-center items-center'><div className='flex justify-center items-center'>
                                <h1 className='font-body font-bold text-4xl sm:text-2xl select-none'>INSCRIERI OPRITE</h1>
                        </div></a>
                        <div className='w-2/4 h-96 flex flex-col gap-x-4 gap-y-4 items-center justify-center'>
                            <a href='/partener' className='partener w-full h-2/4 sm:h-96 sm:w-80 bg-white rounded-xl flex justify-center items-center'><div className='flex justify-center items-center'>
                                <h1 className='font-body font-bold text-4xl sm:text-2xl select-none'>DEVINO PARTENER</h1>
                            </div></a>
                            <a href='/rules' className='regulament w-full h-2/4 sm:h-96 sm:w-80 bg-white rounded-xl flex justify-center items-center'><div className='flex justify-center items-center'>
                                <h1 className='font-body font-bold text-4xl sm:text-2xl select-none'>REGULAMENT</h1>
                            </div></a>
                        </div>
                    </div>

                    <div className="w-2/4 flex flex-col items-center justify-center mt-12 gap-x-2 gap-y-2">
                    <h1 className='text-3xl font-bold font-body sm:text-lg'>EVENT STARTS IN</h1>
                        <div className='bg-[#2B8477] p-2 rounded-xl flex flex-col items-center justify-center shadow-xl'>
                        <Countdown/>
                        </div>
                    </div>

                    <div className="w-2/4 flex flex-col items-center justify-center mt-12 gap-x-2 gap-y-2">
                        <h1 className='text-3xl font-bold font-body sm:text-lg'>SPONSORS</h1>
                        <div className='flex flex-inline justify-center items-center p-4 gap-x-2 gap-y-2'>
                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.ang-custom.com'><img src="/ag.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/moments_oviphotographer?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><img src="/ovi.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/gd_detailing.bv?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><img src="/gd.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>
                        </div>

                        <div className='flex flex-inline justify-center items-center p-4 gap-x-2 gap-y-2'>
                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/moments.visualvibes?igsh=cDlydWo3N3k4MGlk '><img src="/visualvibes.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/c_m_concept?igsh=MWNqMXFseW4wN2NicQ== '><img src="/cmconcepts.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/dreams_store_adt?igsh=MTF1MGZ2ZWxqdWUyOQ== '><img src="/dreamstore.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>
                        </div>

                        <div className='flex flex-inline justify-center items-center p-4 gap-x-2 gap-y-2'>
                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='#'><img src="/MrMuchines.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/dulce_tentatie?igsh=MTRidXFycGxocGcwOQ=='><img src="/dulcetentatie.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/soundforfunconstanta?igsh=MW05ZXVyZ24yZzBvZw== '><img src="/sound4fun.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>
                        </div>

                        <div className='flex flex-inline justify-center items-center p-4 gap-x-2 gap-y-2'>
                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/meritatotibaniimtb?igsh=aGlhY3hlcDJlYnl6'><img src="/MTB.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/sum179_shop?igsh=cTVrMTBsMHVhMXln'><img src="/sum179.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>

                            <div className="w-24 h-24 bg-[#2A474B]/75 shadow-2xl flex items-center justify-center rounded-2xl hover:opacity-75">
                                <a href='https://www.instagram.com/cds.solutions.bv?igsh=emY1OGw0YmRvb2gz '><img src="/CDSsolutions.png" alt="checkin" className="h-20 rounded-full"/></a>
                            </div>
                        </div>
                    </div>


                    <div className='w-full bg-black/75 flex flex-inline items-center justify-center mt-12 gap-x-2 gap-y-2 p-8'>
                    <img src="/ww.png" alt="logo" className="animate__animated animate__fadeInRight m-0 h-16 w-32 sm:h-6 sm:w-12"/>
                    <img src="/azuga.png" alt="logo" className="animate__animated animate__fadeInRight m-0 h-16 sm:h-6"/>
                    <a className='font-body text-lg sm:text-sm'>Copyright 2024 © Wheels & Whistles</a>
                    </div>

                </div> 

                </div>
            </div>
        </div>
    </>
  );
};

export default Home;
