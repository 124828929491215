const Navbar = () => {

    return (
        <>
        <div className="absolute top-0 left-0 w-full z-[10] p-4">
            <div className="flex flex-col items-center justify-center">
                <img src="/bvmostwanted.png" alt="bvmw" className="h-8 w-auto"/>
                <div className="flex flex-inline items-center justify-center gap-x-4 mt-2">
                    <a href="/home" className="text-[12px] hover:font-bold">ACASA</a>
                    <a href="/rules" className="text-[12px] hover:font-bold">REGULAMENT</a>
                    <a href="/shop" className="text-[12px] hover:font-bold">SHOP</a>
                </div>
            </div>
        </div>
        </>
    )
}

export default Navbar;