import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const targetDate = new Date('2024-07-19T12:00:00').getTime(); // Data de expirare
  const [countdown, setCountdown] = useState({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30)); // Aproximativ 30 zile pe lună
      const days = Math.floor((difference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setCountdown({ months, days, hours, minutes, seconds });
    } else {
      console.log('Countdown a expirat!');
      setCountdown({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  useEffect(() => {
    const interval = setInterval(calculateTimeLeft, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
      <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
      <div className="flex flex-col p-2 text-white">
          <span className="countdown font-mono text-5xl font-bold font-body sm:text-xl">
            <span style={{ "--value": countdown.months }}></span>
          </span>
          months
        </div>
        <div className="flex flex-col p-2 text-white">
          <span className="countdown font-mono text-5xl font-bold font-body sm:text-xl">
            <span style={{ "--value": countdown.days }}></span>
          </span>
          days
        </div>
        <div className="flex flex-col p-2 text-white">
          <span className="countdown font-mono text-5xl font-bold font-body sm:text-lg">
            <span style={{ "--value": countdown.hours }}></span>
          </span>
          hours
        </div>
        <div className="flex flex-col p-2 text-white">
          <span className="countdown font-mono text-5xl font-bold font-body sm:text-lg">
            <span style={{ "--value": countdown.minutes }}></span>
          </span>
          min
        </div>
        <div className="flex flex-col p-2 text-white">
          <span className="countdown font-mono text-5xl font-bold font-body sm:text-lg">
            <span style={{ "--value": countdown.seconds }}></span>
          </span>
          sec
        </div>
      </div>
  );
};

export default Countdown;
