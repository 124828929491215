import Navbar from "../components/Navbar";
import React, { useState, useEffect } from "react";
import { createStars } from '../components/Stars';

const Partener = () => {
  const [formData, setFormData] = useState({
    numePrenume: "",
    email: "",
    numarTelefon: "",
    numarImatriculare: "",
    descriere: "",
  });
  const [submitMessage, setSubmitMessage] = useState(""); // Starea pentru mesajul de trimitere

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://api.oxidev.ro/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitMessage("Formular trimis cu succes!"); // Setează mesajul de succes
        setTimeout(() => {
          setSubmitMessage(""); // Șterge mesajul după 5 secunde
        }, 5000);
      } else {
        throw new Error("Eroare la trimiterea formularului!");
      }
    } catch (error) {
      console.error("Eroare la trimiterea formularului:", error);
      setSubmitMessage("Eroare la trimiterea formularului! Încercați din nou în 5 minute."); // Setează mesajul de eroare
      setTimeout(() => {
        setSubmitMessage(""); // Șterge mesajul după 5 secunde
      }, 5000);
    }

    setFormData({
      numePrenume: "",
      email: "",
      numarTelefon: "",
      numarImatriculare: "",
      descriere: "",
    });
  };

  useEffect(() => {
    createStars();
  }, []);

  return (
    <>
      <Navbar />

      <div className="star-field mt-24 w-full flex flex-col h-screen items-center justify-center font-body">
      {submitMessage && <p className="mt-4 text-white">{submitMessage}</p>} {/* Afișează mesajul de trimitere */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 bg-black/75 rounded-xl p-6">
          <input
            type="text"
            name="numePrenume"
            placeholder="NUME/PRENUME"
            value={formData.numePrenume}
            onChange={handleChange}
            className="p-2 rounded-2xl bg-[#2B8477] text-[#ffffff] focus:outline-none"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="MAIL"
            value={formData.email}
            onChange={handleChange}
            className="p-2 rounded-2xl bg-[#2B8477] text-[#ffffff] focus:outline-none"
            required
          />
          <input
            type="tel"
            name="numarTelefon"
            placeholder="NUMAR TELEFON"
            value={formData.numarTelefon}
            onChange={handleChange}
            className="p-2 rounded-2xl bg-[#2B8477] text-[#ffffff] focus:outline-none"
            required
          />
          <input
            type="text"
            name="numarImatriculare"
            placeholder="FIRMA REPREZENTATA"
            value={formData.numarImatriculare}
            onChange={handleChange}
            className="p-2 rounded-2xl bg-[#2B8477] text-[#ffffff] focus:outline-none"
            required
          />
          <textarea
            name="descriere"
            placeholder="OFERTA"
            value={formData.descriere}
            onChange={handleChange}
            className="p-2 rounded-2xl bg-[#2B8477] text-[#ffffff] focus:outline-none"
            required
          />
          <button
            type="submit"
            className="p-2 rounded-2xl bg-[#2B8477] text-[#ffffff] font-bold text-lg hover:bg-[#56C2B2] focus:outline-none"
          >
            Trimite
          </button>
        </form>
      </div>
    </>
  );
};

export default Partener;
